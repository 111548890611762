import { render, staticRenderFns } from "./TrainingFiles.vue?vue&type=template&id=44d6624f&scoped=true&lang=pug&"
import script from "./TrainingFiles.vue?vue&type=script&lang=ts&"
export * from "./TrainingFiles.vue?vue&type=script&lang=ts&"
import style0 from "./TrainingFiles.vue?vue&type=style&index=0&id=44d6624f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44d6624f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VHover,VIcon})
