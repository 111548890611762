









































import { Component, Mixins } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import FilesList from '@/components/FilesList.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterDepositoryModule from '@/store/modules/master/depository'
import {
  CourseFolderListResource,
  IMasterDepositoryTrainingFilesFilterType, KnowledgeSearchResultItemResource,
  MasterKnowledgeCourseIdSearchGetParams,
} from '@/store/types'

@Component({
  components: {
    FilesList,
    Tag,
    TextInput,
  },
})
export default class TrainingFiles extends Mixins(NotifyMixin) {
  private get courseID() {
    return +this.$route.params.courseID
  }

  private get trainingFilesMonths() {
    return MasterDepositoryModule.trainingFilesMonth
  }

  private get filter() {
    return MasterDepositoryModule.trainingFilesFilter
  }

  private set filter(filter: MasterKnowledgeCourseIdSearchGetParams) {
    MasterDepositoryModule.setTrainingFilesFilter(filter)
    this.fetchTrainingFiles()
  }

  private get isFilterActive() {
    return Boolean(this.filter.query)
  }

  private resultSearch: KnowledgeSearchResultItemResource[] = []

  private mounted() {
    if (!this.trainingFilesMonths.courseID || this.courseID !== this.trainingFilesMonths.courseID) {
      MasterDepositoryModule.fetchTrainingFilesMonth({ courseID: this.courseID })
        .catch(err => {
          if (err && err.response && err.response.status === 403) {
            this.$router.replace({ name: 'master.depository' })
              .catch(() => {return})
          }
          this.notifyError(err)
        })
    }

    if (this.isFilterActive) {
      this.fetchTrainingFiles()
    }
  }

  private handleNext(folder: CourseFolderListResource) {
    this.$router.push({ name: 'master.depository.item.files.month', params: { courseID: this.courseID.toString(), monthID: folder.id.toString() } })
  }

  private handleFilter(key: IMasterDepositoryTrainingFilesFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [key]: value,
    }
  }

  @Bind
  @Debounce(300)
  private fetchTrainingFiles() {
    MasterDepositoryModule.fetchTrainingFiles({
      courseID: this.courseID,
      params: this.filter,
    })
      .then(response => {
        this.resultSearch = response
      })
      .catch(this.notifyError)
  }
}
